*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Source Sans Pro', sans-serif;
}

:root {
  --Primary: #076479;
  --secondary:teal ;
  --palestinean:#007A3D;
  --israeli: #0038b8;
  /* #007A3D green 
    #0a95b4 blue*/
  --background: #d8e3e7;
  /* #f4f9f9; */
}


body {
  background: teal;
  color: var(--background);
  overflow-x: hidden;
  padding-bottom: 50px;
}


a{
  text-decoration: none;
  color: var(--background);
}

p{
  padding-top:8px;
  line-height: 1.5;
}

h1,h3{
  text-align: center;
}

h1 {
  color: var(--background);
  font-size: 2.5rem;
}

h3,h4{
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}

.references{
  padding-left: 10px;
  padding-right:10px;
  background-color: var(--secondary);
  border-radius: 5px;
}

/* IMAGES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
img{
  align-self: center;
  max-width:400px;
  max-height: 500px;
  width: 100%;
  margin-top: 1rem;
}

.evidence{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  }

  .isr-flag{
    max-height: 200px;
  }



/* INTRO SECTION
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.intro {
    background: teal;
    padding: 50px 0;
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    
  }

  .text-ListItem{
    padding-top:10px;
    text-align: left;
  }


  .diff{
    /* color: rgb(5, 75, 150);  */
    color:orange;
    font-weight: bold;
    padding:2px;
  }
  
 /* BUTTONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */ 
.btns{
  margin-top:1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn{
  margin: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: transparent;
  color: #fff;
  border: 1px solid var(--background);
  transition: all 0.3s ease-out;
  border-radius:0.4rem;
}


.btn:hover {
  transition: all 0.5s ease-out;
  background-color: var(--background);
  color:teal;
  transition: 250ms;
} 

/* Basics
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.basics{
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.basics h3{
  margin-top: 0;
  text-align: center;
}


.pal-basics,.isr-basics{
  padding:1rem;
  background-color:rgb(18, 62, 112); 
  width:40%;
  border-radius: 5px;
  box-shadow: 3px 3px 7px 4px rgba(7, 23, 41, 0.8); 
  display: flex;
  flex-direction: column;
  
}

.solutions-basics{
  padding:1rem;
  background-color:rgb(18, 62, 112); 
  width:90%;
  border-radius: 5px;
  box-shadow: 3px 3px 7px 4px rgba(7, 23, 41, 0.8); 
  display: flex;
  flex-direction: column;
  text-align: left;

}

.link{
  text-decoration: underline;
}



.ListBasics{
  padding-left: 50px;
}

.expandable{
  display: flex;
  gap:10px;
  align-items: center;
}

.expand{
  font-size: 2rem;
  font-weight: bold;
  color:orange;
  cursor: pointer;

}

.hide{
  
  opacity:0;
  visibility: hidden;
  z-index: -1;
  max-height: 0;
  transition: all 500ms ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
}

.show{
  display: flex;
  flex-direction: column; 
  max-height: 500rem;
  z-index: 1;
  opacity:1;
  transition: all 800ms ease-in-out;

}



/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-title{
  color: teal;
  background-color: var(--background);
  text-align: center;
  padding-top: 2rem;
}



.timeline ul {
    /* background: #456990; */
    background: var(--background);
    padding: 50px 0;
  }
  
  .timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: black;
    left:-35%;
  }

  .timeline ul li::after { /* what this does is create a pseudo element after the last child of the parent in this case the Li and then add the content property to it*/
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--secondary);
    z-index: 1;
  }

  .timeline ul li div {
    position: relative;
    bottom: 0;
    width: calc(80vw - 200px);
    padding: 15px;
    background:var(--Primary);
    border-radius: 5px;
  }

  .timeline ul li div::before {
    content: "";
    position: absolute;
    bottom: 7px;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .timeline ul li:nth-child(odd) div {
    left: 35px;
  }

  .timeline ul li:nth-child(odd) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent var(--Primary) transparent transparent;
  }
  
  
  .timeline ul li:nth-child(even) div {
    /* left: -439px; THIS IS FOR ALTERNATE TIMELINE ITEMS */
    left: 35px; /*THIS IS FOR SERIAL TIMELINE ITEMS*/
  }

  
  .timeline ul li:nth-child(even) div::before {
    
    
    /*---THIS IS FOR SERIAL---*/
    left: -15px; 
    border-width: 8px 16px 8px 0;
    border-color: transparent var(--Primary) transparent transparent;

    /*---THIS IS FOR ALTERNATE---
     right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent var(--Primary); */
  }
  
  time {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
    background: var(--secondary);
    text-align: center;
    border-radius: 5px;
  
  }

  

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 1200px) {
  .timeline ul li div {
    width: calc(90vw - 100px);
  }
  .timeline ul li {
    left:-40%;
  }
 
}


@media screen and (max-width: 768px) {

  .timeline ul li {
    margin-left: 20px;
  }

   .timeline ul li div {
    width: calc(100vw - 90px);
  }

  .timeline ul li {
    left:0%;
  }
  .timeline ul li:nth-child(even) div {
    left: 35px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent var(--Primary) transparent transparent;
  }

  .basics{
    flex-direction: column;
    gap:2rem;
  }
  
  .pal-basics,.isr-basics{
    width:100%;
    max-width: 400px;
  }

  
  .solutions-basics{
    width:90%;
    max-width: 400px;
  }
 
 
  
}

@media screen and (max-width: 400px) {

h1{
  font-size: 2rem;
}

h3{
  font-size: 0.95rem;
}
h4{
  font-size: 0.9rem;
}
p{
  font-size: 0.85rem;
}


}

/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  background-color: teal;
  right: 0;
  bottom: 20px;
  text-align: center;
  padding: 5px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}

.closing{
  padding:20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}